(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
/*jshint unused:false,supernew:true*/
'use strict';

(function(W, U) {
  var $ = W.jQuery,
      _ = W._,
      Promise = W.Promise,
      toastr = W.toastr;
  
  W.util = {
    EncodeQueryData: function EncodeQueryData(data) {
      var ret = [];
      for (var d in data){
        if(data.hasOwnProperty(d)){
          ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
        }
      }
      return ret.join("&");
    },

    formatCurrency: function formatCurrency(amount, dollarSign) {
      if (dollarSign === true || typeof dollarSign === 'undefined') dollarSign = '$';
      if (amount) {
        if (typeof amount !== 'string' && amount.toFixed(2) != null) {
          amount = amount.toFixed(2).toString();
        }
        return (dollarSign ? dollarSign : '') + amount.replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      }
      return (dollarSign ? dollarSign : '') + '0.00';
    },

    formatPercent: function formatPercent(amount) {
      if (amount) {
        if (typeof amount !== 'string' && amount.toFixed(2) != null) {
          amount = amount.toFixed(2).toString();
        }
        return amount.replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '%';
      }
      return '0.00%';
    },

    generateUUID: function () {
      var d = new Date().getTime();
      if(window.performance && typeof window.performance.now === "function"){
        d += performance.now(); //use high-precision timer if available
      }
      var uuid = 'xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
      });
      return uuid;
    }
  };

}(self));
},{}]},{},[1])

